<template>
  <RFModal :modalId="modalId" v-if="reservation"
  :title="type === accommodations.ROOM ? `${reservation.buildingName} - ${reservation.roomType.name}` : reservation.apartment.name"
  @send="accept" @cancel="reject" :loading="loading" ok-label="Accept" cancel-label="Cancel" has-cancel @hidden="hideModal">
    <b-row class="reservation-data">
      <b-col class="reservation-label">
        {{`${$t('Reservation N°')}: ${reservation.id}`}}
      </b-col>
      <b-col class="reservation-label">
        {{reservation.startDate | extractMonthLocaleFromDate}} {{reservation.startDate | extractYearFromDate}} - {{reservation.expiringDate | extractMonthLocaleFromDate}} {{reservation.expiringDate | extractYearFromDate}}
      </b-col>
    </b-row>
    <div class="guests">
      <b-row class="mt-4 mb-3">
        <b-col class="guests-fields">{{$t('Guest')}}</b-col>
        <b-col class="guests-fields">{{$t('Nationality')}}</b-col>
        <b-col class="guests-fields">{{$t('Birth Date')}}</b-col>
        <b-col class="guests-fields">{{$t('Account Holder')}}</b-col>
      </b-row>
      <b-row class="guest-data mb-3" v-for="(guest, index) in guests" :key="index">
        <b-col class="guest-label">{{`${guest.firstname} ${guest.lastname}`}}</b-col>
        <b-col class="guest-label">{{guest.nationality}}</b-col>
        <b-col class="guest-label">{{guest.birthDate | formatDate}}</b-col>
        <b-col class="guest-label">
          <FontAwesomeIcon color="#5CB85C" v-if="guest.isPrimary"
            :icon="['fas', 'check-circle']"/>
        </b-col>
      </b-row>
    </div>
    <div class="rooms" v-if="type === accommodations.ROOM">
      <div class="available-title">{{$t('Available Rooms')}}</div>
      <b-row>
        <b-col class="rooms-fields">{{$t('Name')}}</b-col>
        <b-col class="rooms-fields">{{$t('Type')}}</b-col>
        <b-col class="rooms-fields">{{$t('Admin Comment')}}</b-col>
        <b-col class="rooms-fields">{{$t('Floor')}}</b-col>
        <b-col class="rooms-fields">{{$t('Select')}}</b-col>
      </b-row>
      <b-row class="room" :class="{ 'selected': selectedRoom && room.id === selectedRoom.id }" v-for="(room, index) in rooms" :key="index" @click="selectedRoom = room">
        <b-col class="room-detail">
          <div class="room-detail-label" v-if="room.name">{{room.name}}</div>
        </b-col>
        <b-col class="room-detail">
          <div class="room-detail-label type" v-if="room.type.name">{{room.type.name}}</div>
        </b-col>
        <b-col class="room-detail">
          <div class="room-detail-label" v-if="room.adminComment">{{room.adminComment}}</div>
        </b-col>
        <b-col class="room-detail">
          <div class="room-detail-label" v-if="room.floor">{{room.floor}}</div>
        </b-col>
        <b-col class="room-detail">
          <transition name="fade">
            <div class="selected-room" v-if="selectedRoom && room.id === selectedRoom.id">
              <FontAwesomeIcon color="#5CB85C" :icon="['fa', 'check-circle']"/>
            </div>
          </transition>
        </b-col>
      </b-row>
      <div class="occuped-rooms mt-5" v-if="occupedRooms.length">
        <div class="occuped-title">{{$t('Occuped Rooms')}}</div>
        <b-row>
          <b-col class="occuped-fields">{{$t('Name')}}</b-col>
          <b-col class="occuped-fields">{{$t('Type')}}</b-col>
          <b-col class="occuped-fields">{{$t('Admin Comment')}}</b-col>
          <b-col class="occuped-fields">{{$t('Floor')}}</b-col>
          <b-col class="occuped-fields">{{$t('View guests')}}</b-col>
        </b-row>
        <b-row class="room" v-for="(occupedRoom, index) in occupedRooms" :key="`occuped-${index}`">
          <b-col class="room-detail">
            <div class="room-detail-label" v-if="occupedRoom.room.name">{{occupedRoom.room.name}}</div>
          </b-col>
          <b-col class="room-detail">
            <div class="room-detail-label type" v-if="occupedRoom.room.type">{{occupedRoom.room.type.name}}</div>
          </b-col>
          <b-col class="room-detail">
            <div class="room-detail-label" v-if="occupedRoom.room.adminComment">{{occupedRoom.room.adminComment}}</div>
          </b-col>
          <b-col class="room-detail">
            <div class="room-detail-label" v-if="occupedRoom.room.floor">{{occupedRoom.room.floor}}</div>
          </b-col>
          <b-col class="room-detail">
            <FontAwesomeIcon v-if="index !== selectedOccupedRoom" class="cursor-pointer" :icon="['fas', 'plus']" @click="selectOccupedRoom(index)"/>
            <FontAwesomeIcon v-else class="cursor-pointer" :icon="['fas', 'minus']" @click="selectOccupedRoom(index)"/>
          </b-col>
          <b-col cols="12" class="occuped-room-guests" :class="{ 'open': index === selectedOccupedRoom }">
            <b-row class="occuped-room-row align-items-center" v-for="(guest, index) in occupedRoom.guests" :key="`guest-${index}`">
              <b-col cols="auto" class="occuped-room-guest">
                <FontAwesomeIcon :icon="['fas', 'user']" />
              </b-col>
              <b-col class="occuped-room-guest">
                {{`${guest.firstname} ${guest.lastname}`}}
              </b-col>
              <b-col class="occuped-room-guest">
                {{guest.birthDate | formatDate}}
              </b-col>
              <b-col class="occuped-room-guest">
                {{guest.genre}}
              </b-col>
              <b-col class="occuped-room-guest">
                {{guest.nationality}}
              </b-col>
              <b-col class="occuped-room-guest" v-if="guest.fiscalCode">
                {{guest.fiscalCode}}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="warning" v-if="slotsOccupied">
      <b-row class="mt-4 mb-3">
        <b-col class="warning-fields">WARNING: Some calendar slots are occupied by another reservation</b-col>
      </b-row>
    </div>
  </RFModal>
</template>

<script>
import RFModal from '@/components/modals/RFModal'
import { adminService } from '@/services/admin'
import { accommodations, reservationStatus } from '@/enums'
import { mapState } from 'vuex'

export default {
  name: 'ReservationModal',
  components: {
    RFModal,
  },
  props: {
    type: String,
  },
  data() {
    return {
      accommodations,
      reservationStatus,

      modalId: 'reservation-modal',
      rooms: [],
      occupedRooms: [],
      selectedRoom: null,
      guests: [],
      selectedOccupedRoom: null,
      loading: false,
    }
  },
  computed: {
    ...mapState({
      reservation: state => state.adminStore.reservation
    }),
    reservationGuests() {
      return this.reservation ? this.reservation.guests : []
    },
    slotsOccupied(){
      let slotsOccupied = false
      if(this.reservation){
        let startDate = new Date(this.reservation.startDate)
        let expiringDate = new Date(this.reservation.expiringDate)
        if(this.reservation.apartment!==null){
          slotsOccupied = this.reservation.apartment.periods.some((calendarSlot) => (new Date(calendarSlot.date))>=startDate && (new Date(calendarSlot.date)<=expiringDate))
        }
        else if(this.selectedRoom!==null){
          slotsOccupied = this.selectedRoom.periods.some((calendarSlot) => (new Date(calendarSlot.date))>=startDate && (new Date(calendarSlot.date)<=expiringDate))
        }
      }
      return slotsOccupied
    }
  },
  beforeMount() {
    this.initReservationModal()
  },
  methods: {
    async initReservationModal() {
      if (this.reservation) {
        this.setGuests()

        if (this.type === accommodations.ROOM) {
          if (this.reservation) {
            await this.getAvailableRooms()
            await this.getOccupedRooms()
          }
        }
      }
    },
    async getAvailableRooms() {
      this.rooms = await adminService.getBuildingRoomsByTypes(this.reservation.buildingId, this.reservation.roomType.id)
    },
    async getOccupedRooms() {
      this.occupedRooms = await adminService.getBuildingOccupiedRooms(this.reservation.buildingId, this.reservation.startDate)

      this.occupedRooms.forEach(occupedRoom => occupedRoom.guests = [...occupedRoom.guests, occupedRoom.user])
    },
    selectOccupedRoom(index) {
      if (this.selectedOccupedRoom === index) {
        this.selectedOccupedRoom = null
      } else {
        this.selectedOccupedRoom = index
      }
    },
    setGuests() {
      if (this.reservation) {
        this.guests = []
        this.guests = this.reservation.guests
        this.reservation.user.isPrimary = true
        this.guests = [...this.guests, this.reservation.user]
        this.guests.reverse()
      }
    },
    async accept() {
      if (this.type === accommodations.ROOM && !this.selectedRoom) {
        this.$toasted.info(this.$i18n.t('Select a room'))
        return false
      }

      if(this.slotsOccupied){
        this.$toasted.error(this.$i18n.t(`An error occurred: Some calendar slots are occupied by another reservation`))
        return false
      }

      this.loading = true

      const { data: reservation, status } = this.type === accommodations.ROOM
            ? await adminService.acceptRoomReservation(this.reservation.id, this.selectedRoom.id)
            : await adminService.updateReservation(this.reservation.id, reservationStatus.ACCEPTED)

      if (status === 200) {
        this.$root.$emit('bv::hide::modal', this.modalId)
        this.$emit('update', reservation)
        this.$toasted.success(this.$i18n.t(`Booking successfully accepted!`))
        this.$store.commit('adminStore/setReservation', null)
        this.loading = false
      } else {
        this.$toasted.error(this.$i18n.t(`An error occurred`))
        this.loading = false
      }
    },
    async reject() {
      const { data: reservation, status } = await adminService.updateReservation(this.reservation.id, reservationStatus.REFUSED)

      if (status === 200) {
        this.$root.$emit('bv::hide::modal', this.modalId)
        this.$emit('update', reservation)
      } else {
        this.$toasted.error(this.$i18n.t('An error occurred'))
      }
    },
    hideModal(){
      this.selectedRoom = null
    }
  },
  watch: {
    reservation: {
      handler() {
        this.initReservationModal()
        // this.setGuests()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#reservation-modal {
  .reservation-title {
    @include font-style($montserrat, 'semibold', $font-20);
    text-transform: capitalize;
    color: $white;
    margin: 0;
  }
  .reservation-data {
    margin: 10px 0;

    .reservation-label {
      @include font-style($montserrat-bold, 'bold', $font-16);
      color: $dark-blue;
      text-align: center;
    }
  }
  .guests {
    .guests-fields {
      @include font-style($montserrat-bold, 'bold', $font-16);
      color: $dark-blue;
      text-align: center;
    }
    .guest-label {
      @include font-style($montserrat, 'semibold', $font-16);
      color: $dark-blue;
      text-align: center;
    }
  }
  .rooms {
    margin-top: 50px;
    height: 400px;
    overflow: hidden;
    overflow-y: auto;

    .available-title {
      @include font-style($montserrat, 'semibold', $font-20);
      color: $dark-blue;
      border-bottom: 1px solid $gray;
      margin: 0 40px 20px 40px;
    }
    .rooms-fields {
      @include font-style($montserrat-bold, 'bold', $font-16);
      color: $dark-blue;
      text-align: center;
    }
    .room {
      padding: 10px;
      cursor: pointer;
      transition: background-color .3s;

      &:hover {
        background-color: rgba($gray, .5);
      }
      &.selected {
        background-color: $gray;
      }
      .room-detail {
        @include font-style($montserrat, 'medium', $font-14);
        display: flex;
        align-items: center;
        justify-content: center;

        .room-detail-label {
          &.type {
            text-transform: capitalize;
          }
        }
      }
    }
    .occuped-rooms {
      .occuped-title {
        @include font-style($montserrat, 'semibold', $font-20);
        color: $dark-blue;
        border-bottom: 1px solid $gray;
        margin-bottom: 20px;
        margin: 0 40px 20px 40px;
      }
      .occuped-fields {
        @include font-style($montserrat-bold, 'bold', $font-16);
        color: $dark-blue;
        text-align: center;
      }
      .room {
        cursor: default;

        &:hover {
          background-color: $white;
        }
        .occuped-room-guests {
          background-color: rgba($gray, .5);
          border-bottom: .5px solid $black;
          padding: 0 50px;
          text-align: center;
          max-height: 0;
          overflow-y: hidden;
          transition: all .3s ease-in-out;

          &:last-child {
            border-bottom: none;
          }
          &.open {
            max-height: 1000px;
            overflow-y: visible;
          }
          .occuped-room-row {
            height: 50px;

            .occuped-room-guest {
              @include font-style($montserrat, 'medium', $font-14);
              color: $dark-blue;
            }
          }
        }
      }
    }
  }
  .apartment {
    margin-top: 30px;
    .apartment-infos {

    }
    .apartment-gmap {
      height: 200px;
    }
  }
  .warning {
    .warning-fields {
      @include font-style($montserrat-bold, 'bold', $font-16);
      color: $danger;
      text-align: center;
    }
    .warning-label {
      @include font-style($montserrat, 'semibold', $font-16);
      color: $danger;
      text-align: center;
    }
  }
}
</style>
