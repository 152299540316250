<template>
  <div>
    <div v-if="showReservationsTable" class="admin-reservation-rooms">
      <ReservationsTable title="Apartment Reservations To Accept"
                  :list="reservationList" :fields="fields" :loading="loading" :availabilityButton="true" :manageAvailabilityButton="true"
                  @update-list="update" @open-modal="openModal" @show-availability="manageAvailabilityTables(false,false)" @manage-availability="manageAvailabilityTables(false,true)"/>
      <ReservationModal @update="update" :type="accommodations.APARTMENT"/>
    </div>
    <div v-else>
      <AdminAvailabilityTable title="All Apartments Availability"
                              :list="apartmentList" :loading="loading" :manageAvailability="manageAvailability" :isApartment="true"
                              @update-list="update" @show-reservations="manageAvailabilityTables(true,false)"/>
    </div>
  </div>
</template>

<script>
import ReservationsTable from '@/components/admin/ReservationsTable'
import AdminAvailabilityTable from '@/components/admin/AdminAvailabilityTable'
import ReservationModal from '@/components/admin/reservations/ReservationModal'
import { adminService, apartmentService } from '@/services/admin'
import { accommodations, reservationStatus } from '@/enums'
import { Reservation } from '@/models/reservation.js'

export default {
  name: 'AdminRoomReservations',
  components: {
    ReservationsTable,
    AdminAvailabilityTable,
    ReservationModal,
  },
  data() {
    return {
      accommodations,
      reservationList: [],
      apartmentList: [],
      loading: false,
      showReservationsTable: true,
      adminAvailabilityTableTitle: "All Apartments Availability",
      manageAvailability:false
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Unit Name' },
        { key: 'apartment.addressCity', label: 'City' },
        { key: 'apartment.addressStreet', label: 'Address' },
        { key: 'apartment.floor', label: 'Floor' },
        { key: 'startDate', label: 'From', type: 'date' },
        { key: 'expiringDateAux', label: 'To', type: 'date' },
        { key: 'firstname', label: 'Firstname' },
        { key: 'lastname', label: 'Lastname' },
        { key: 'email', label: 'Email' },
        { key: 'status' },
        { key: 'actions', label: 'Actions', type: 'actions', actions: ['show'] },
      ]
    },
  },
  async beforeMount() {
    await this.update()
  },
  methods: {
    openModal(item) {
      this.$store.commit('adminStore/setReservation', item)
      this.$root.$emit('bv::show::modal', 'reservation-modal')
    },
    async update() {
      this.loading = true
      this.reservationList = []
      let [reservationList, apartmentList] = await Promise.all([
        adminService.getReservationList(reservationStatus.COMPLETED, accommodations.APARTMENT.toUpperCase()),
        apartmentService.getApartments()
      ])
      reservationList.forEach(element => {
        this.reservationList.push(new Reservation(element))
      });
      this.apartmentList = apartmentList
      this.loading = false
    },
    //RF-87
    manageAvailabilityTables(showOriginalTable,manageAvailability){
      this.showReservationsTable = showOriginalTable
      if(!this.showReservationsTable){
        this.manageAvailability = manageAvailability
        if(manageAvailability){
          this.adminAvailabilityTableTitle = 'Manage Apartments Availability'
        }
        else{
          this.adminAvailabilityTableTitle = 'All Apartments Availability'
        }
      }
      else{
        this.adminAvailabilityTableTitle = 'All Apartments Availability'
        this.manageAvailability = false
      }
    }
  }
}
</script>

<style>

</style>
